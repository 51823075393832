import { AnimatePage } from 'Atoms/AnimatePage'
import Link from 'next/link'

export default function FourOhFour() {
  return (
    <AnimatePage>
      <div className='grid h-screen place-items-center'>
        <div className='text-center text-6xl headline font-normal p-4 m-2'>
          <h1 className='mb-8'>Oops, page not found.</h1>
          <Link href="/">
            <a> <span className='p-1 underlined relative'>View Homepage</span></a>
          </Link>
        </div>
      </div>
    </AnimatePage >
  )
}
